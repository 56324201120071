import returIcon from 'assets/IconsSvg/return.svg';
import PixIcon from 'assets/icons/pix-icon.svg';
import StoreIcon from 'assets/icons/store-icon.svg';
import SuccessIcon from 'assets/icons/success.svg';
import TruckIcon from 'assets/icons/truck-icon.svg';
import Bandeiras from 'assets/IconsSvg/bandeiras.svg';
import CardImage from 'assets/images/card-image.svg';
import FieldError from 'components/Forms/FieldError';
import Modal from 'components/Modals/Modal';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { mask } from 'remask';
import Text from '../../components/Typografy/Text';
import api from 'services/api';
import { getCep } from 'services/cep.service';
import { getFreight } from 'services/freight.service';
import { listStore } from 'services/customers-services';
import { getCardBrand, getRequestsChangeStatus } from 'services/requests-services';
import * as Request from 'services/requests-services';
import * as Reshop from 'services/reshop-services';
import { Types as CartTypes } from 'store/ducks/Checkout';
import { Types as NsuTypes } from 'store/ducks/Nsu';
import { StyledFlexContainer } from 'styles/global';
import theme from 'styles/theme';
import useWindowDimensions from 'utils/hooks/getScreenSize';
import Icon from '../../components/Icon';
import { Types as CepTypes } from '../../store/ducks/Cep';
import DeliveryOption from './DeliveryOption';
import Input from './Input';
import Operation from './Operation';
import OrderSummary from './OrderSummary';
import PaymentCard from './PaymentCard';
import Product from './Product';
import ResumeCard from './ResumeCard';
import StoreOption from './StoreOption';
import {
  StyledPage,
  StyledLeftContainer,
  StyledList,
  StyledInputContainer,
  StyledCEP,
  StyledDeliveryContainer,
  StyledFields,
  StyledInput,
  StyledLabel,
  StyledRow,
  StyledDescr,
  StyledListStores,
  StyledRightContainer,
  StyledExternalCircle,
  StyledInternalCircle,
  StyledType,
  StyledCardContainer,
  StyledFormCardContainer,
  StyledCardImageContainer,
  StyledContainer,
  StyledRowContent,
  StyledPixContent,
  ModalContainer,
  ModalContainerPix,
  SuccessContainer,
  ProcessingContainer,
  StyledButton,
  StyledLinkContainer,
  StyledOrderContainer,
  StyledCopyButton,
  StyledButtonsContainer,
  StyledAlert,
  StyledLoaderContainer,
  StyledButtonStore,
  ModalPix
} from './styles';

function NewCheckout({ match }) {
  const { search } = useLocation();
  const sellerId = new URLSearchParams(search).get('sellerId');
  const catalogId = new URLSearchParams(search).get('catalogId');
  const windowDimensions = useWindowDimensions();

  const globalState = useSelector(state => state.Checkout);
  const nsu = useSelector(state => state.Nsu);
  const cepData = useSelector(state => state.Cep);
  const storeAddressData = useSelector(state => state.StoreAddress);
  const settings = useSelector(state => state.Setting.data);

  const history = useHistory();

  const total = useSelector(state =>
    state.Checkout.reduce((totalSum, item) => { return totalSum + item.price * item.amount; }, 0),
  );

  const [reference, setReference] = useState('#');

  // Localization states
  const [cep, setCep] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [nearbyStores, setNearbyStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  // Control states
  const [enabledNext, setEnabledNext] = useState(false);
  const [phase, setPhase] = useState(0);
  const [pixModal, setPixModal] = useState(false);
  const [debitModal, setDebitModal] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseApiErro, setResponseApiErro] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [requestId, setRequestId] = useState();
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [timer, setTimer] = useState(10);
  const [isProcessing, setIsProcessing] = useState(false);
  const [copied, setCopied] = useState(false);
  const [textButton, setTextButton] = useState('FECHAR PEDIDO');
  const [errors, setErrors] = useState({});

  // Payment states
  const [promocode, setPromocode] = useState('');
  const [paymentType, setPaymentType] = useState(1);
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [brandCard, setBrandCard] = useState('');
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [installment, setInstallment] = useState('');
  const [validity, setValidity] = useState('');
  const [cvv, setCvv] = useState('');
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [freight, setFreight] = useState(0);
  const [pixCode, setPixCode] = useState('');
  const [qrCode, setQrCode] = useState(null);

  // Personal information states
  const [selectedIdFreightOption, setSelectedIdFreightOption] = useState(0);
  const [personType, setPersonType] = useState(0);
  const [phone, setPhone] = useState('');
  const [cpfField, setCpfField] = useState('');
  const [cnpjField, setCnpjField] = useState('');
  const [socialReason, setSocialReason] = useState('');
  const [name, setName] = useState();
  const [email, setEmail] = useState('');

  // Product states
  const [products, setProducts] = useState([]);
  const [campaign, setCampaign] = useState();
  const [discounts, setDiscounts] = useState(0);

  const dispatch = useDispatch();

  const setReshop = async () => {
    const itens = globalState.map(prod => {
      return {
        ncm: prod.ncm,
        codigoProduto: prod.reference,
        codigoSku: prod.stockKeepingUnit,
        qtde: prod.amount,
        precoUnitario: prod.price,
        valorLiquido: prod.price * prod.amount,
      };
    });

    const body = {
      transacaoAssociada: nsu.data,
      valorLiquido: total - discounts + freight,
      valorBruto: total,
      codigoLoja: '000005',
      parcelas: installment,
      items: itens,
    };

    const response = await Reshop.setConsultaCampanha(body);
    setDiscounts(response?.data?.totalDesconto || 0);
    setCampaign(response?.data?.campanhasAtivadas);

    return response;
  }

  const handleRemove = (id) => {
    dispatch({ type: CartTypes.REMOVE_FROM_CART, id, });
  };

  const handleLinkToShowcase = () => {
    history.goBack()
  };

  const handleLinkToCloseSuccess = () => {
    cleanUpCart();
    history.push(`/${match.params.store}/vitrine?sellerId=${sellerId}`);
  };

  const handleLinkToCloseError = () => {
    setIsSuccess(null);
  };

  const handleLinkToResultClose = () => {
    if (isSuccess == true) {
      handleLinkToCloseSuccess();
    } else {
      handleLinkToCloseError();
    }
  };

  const getOrderInstallments = async () => {
    const response = await Request.getInstallments();
    setInstallmentOptions(response.data.payload);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderInstallments();
  }, [])

  const getFirstRow = () => {
    if (paymentType === 1) {
      return `Cartão Crédito`
    } if (paymentType === 2) {
      return `Cartão Débito`
    }
    return 'Pix'
  };

  const getSecondRow = () => {
    // Debit or Credit
    if (paymentType !== 4) {
      return `Cartão Final ${cardNumber.slice(-4)} - ${paymentType === 1 ? installment.description : '1x'} de R$ ${((total - discounts + freight) / installment.value).toFixed(2)}`
    }
    // Pix
    return `1x ${total - discounts + freight}`
  };

  const getBrandCard = async (bin) => {
    const response = await getCardBrand(bin);
    setBrandCard(response)
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(pixCode);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, [3000]);
  };

  const handleAbort = async () => {
    setPixModal(false);
    setPhase(2);

    await getRequestsChangeStatus({
      OrderIds: requestId,
      NewStatus: 6
    });
  };

  const cleanUpCart = () => {
    dispatch({ type: CartTypes.CLEAR_CART, });
    dispatch({ type: NsuTypes.CLEAR_NSU, });
  }

  const handleNext = (e) => {
    if (phase === 0) {
      setPhase(1);
      setEnabledNext(false);
    } else if (phase === 1) {
      setPhase(2);
      setEnabledNext(false);
      setTextButton('FINALIZAR PEDIDO')
    } else if (phase === 2) {
      handleSubmit(e);
      setTextButton('VOLTAR AO INÍCIO')
      setPhase(3);
    } else if (phase === 3) {
      history.go(-1);;
      cleanUpCart();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phase === 2) {

      if (paymentType === 1 && !(cardNumber.length === 19 && cardName && validity.length === 5 && cvv && installment.description)) {
        return;
      } else if (paymentType === 2 && !(cardNumber.length === 19 && cardName && validity.length === 5 && cvv)) {
        return;
      }

      const brazilPhone = `55${phone.replace(/\(/, '').replace(/\)/, '').replace(/ /g, '').replace(/-/, '')}`;

      const address = {
        streetName: street,
        number,
        complement,
        reference: district,
        city,
        state,
        country: 'BR',
        zipCode: cep.replace(/-/, ''),
      };

      const card = {
        holder: cardName,
        number: cardNumber.replace(' ', '').replace(' ', '').replace(' ', ''),
        securityCode: cvv,
        validation: validity,
        installments: Number(installment.value),
      };

      const user = {
        name,
        cpf: cpfField.replace(/-/, '').replace(/\./, '').replace(/\./, ''),
        email,
        phone: brazilPhone,
      };

      const items = globalState.map(prod => {
        return {
          skuCode: prod.stockKeepingUnit,
          referenceId: prod.reference,
          productVariationId: prod.id,
          name: prod.name,
          description: prod.name,
          quantity: prod.amount,
          price: total - discounts,
          unitCost: prod.price,
          orderDiscounts:
            campaign &&
            campaign?.flatMap(campg =>
              campg.itens
                ?.filter(
                  discountItem =>
                    discountItem.codigoSku === prod.stockKeepingUnit,
                )
                .map(discountItem => {
                  return {
                    discountType: 1,
                    referenceId: campg.id,
                    value: discountItem.valorDescontoItem,
                  };
                }),
            ),
        };
      });

      const pagamentos = [
        {
          numeroPagamento: 1,
          tipo: paymentType,
          valor: total - discounts + freight,
          result: true,
          message: '',
        },
      ];

      const shippinselect = deliveryOptions.filter(c => c.id === selectedIdFreightOption)[0];

      const shipping = {
        shippingId: shippinselect.id,
        shippingName: shippinselect.title,
        DaysToDelivery: shippinselect.days,
        HoursToDelivery: shippinselect.hours,
        storeidpickup: selectedStore !== null ? selectedStore?.storeId : null,
      };

      try {
        setLoading(true);
        const response = await api.post('order', {
          catalogId: parseInt(catalogId, 10) || null,
          shippingPrice: freight,
          coupon: promocode,
          sellerId: parseInt(sellerId, 10) || null,
          orderTypeId: shippinselect.storePickup ? 2 : 1,
          qtdTotal: globalState[0].amount,
          valorBruto: total,
          valorDesconto: discounts,
          valorDescontoUnico: discounts,
          valorLiquido: total - discounts,
          nsu: nsu.data,
          transacaoAssociada: nsu.data,
          user,
          card: paymentType !== 4 ? card : null,
          address,
          items,
          pagamentos,
          shipping
        });

        setLoading(false);

        if (response.data.isError) {
          setIsSuccess(false);
          setResponseApiErro(response.data.message);
          document.documentElement.scrollTop = 0;
        } else if (paymentType === 2) {
          setDebitModal(true);
          openAuthURL(response.data?.payload?.authenticationUrl);
        } else if (paymentType === 4) {
          setPixModal(true);
          setPixCode(response.data?.payload?.qrCode);
          setQrCode(response.data?.payload?.qrCodeUrl)
        } else {
          successOperation();
        }

        setEnabledNext(true);
        setRequestId(response.data?.payload?.generatedId);
        setReference(response.data?.payload?.reference);
      }
      catch (err) {
        failOperation();
        setResponseApiErro(err.response.data.message);
        setEnabledNext(true);
        setLoading(false);
      }

      setBrandCard(await Request.getCardBrand(card.number.substring(0, 6)));
    }
  }

  const openAuthURL = (url) => {
    const a = document.createElement('a');
    a.target = "_blank";
    a.href = url;
    a.click();
  }

  const finishOperation = async () => {
    setIsLoadingStatus(true);
    const response = await Request.getStatusId(requestId);
    setIsLoadingStatus(false);

    if (response.data.payload.payment.statusId === 1) {
      setIsProcessing(true);
    } else if (response.data.payload.payment.statusId === 2) {
      setPixModal(false);
      setDebitModal(false);
      successOperation();
    } else {
      failOperation();
    }
  }

  const verifyPixPayment = async () => {
    if (timer === 0) {
      await finishOperation();
      if (!isLoadingStatus) {
        setTimer(10);
      }
    } else {
      setTimeout(() => {
        setTimer(timer - 1);
      }, [1000])
    }
  }

  const getStores = async () => {
    if (cep.length === 9) {
      const response = await listStore(cep);
      setNearbyStores(response.data.payload);
    }
  }

  useEffect(() => {
    getStores();
  }, [cep]);

  const handleCep = async () => {

    const response = await getCep(cep);
    setDistrict(response.data.bairro);
    setStreet(response.data.logradouro);
    setCity(response.data.cidade);
    setState(response.data.estado);

    const responseFreights = await getFreight(cep);
    setDeliveryOptions(responseFreights.data.payload)
  }

  useEffect(() => {
    if (pixModal) { verifyPixPayment(); }
  }, [timer, pixModal])

  const failOperation = () => {
    setIsSuccess(false);
    setPhase(2);
    setEnabledNext(false);
    window.scrollTo(0, 0);
    document.documentElement.scrollTop = 0;
  }

  const successOperation = () => {
    setIsSuccess(true);
    setSuccessOrder(true);
    window.scrollTo(0, 0);
    setTextButton('VOLTAR AO INÍCIO');
  }

  useEffect(() => {
    const element = document.getElementsByClassName('phase-name');
    if (element[0] && phase > 0) {
      window.scroll({
        top: element[0].offsetTop - 75,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [phase])

  useEffect(() => {
    setProducts(globalState);
    setReshop();
    if (successOrder === false) {
      if (globalState.length === 0) {
        handleLinkToShowcase();
      }
    }
  }, [globalState]);

  useEffect(() => {
    if (cardNumber.length === 19) {
      const bin = `${cardNumber.substring(0, 4)}${cardNumber.substring(5, 7)}`;
      getBrandCard(bin);
    }
  }, [cardNumber]);

  useEffect(() => {
    if (phase === 0) {
      setTextButton('FECHAR PEDIDO')
      setEnabledNext(cep.length === 9 && ((street && number && city && state && district) || selectedStore) && selectedIdFreightOption > 0);
    }
  }, [phase, cep, street, number, city, state, district, selectedStore, selectedIdFreightOption]);

  useEffect(() => {
    if (phase === 1) {
      setTextButton('FECHAR PEDIDO')
      setEnabledNext()

      if (personType === 0) {
        setEnabledNext(cpfField.length === 14 && cpf.isValid(cpfField.replace('.', '').replace('.', '').replace('-', '')) &&
          name && name != "" && RegExp(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,} [A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}/).test(name) &&
          email && RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email) &&
          phone.length === 16 && RegExp(/\(\d{2}\)( )?(9)?( )?\d{4}-\d{4}/).test(phone));
      } else {
        setEnabledNext(cnpjField.length === 18 && cnpj.isValid(cnpjField.replace('.', '').replace('.', '').replace('/', '').replace('-', '')) &&
          socialReason && socialReason != "" && RegExp(/\w{3,} \w{3,}/).test(socialReason) &&
          email && RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email) &&
          phone.length === 16 && RegExp(/\(\d{2}\)( )?(9)?( )?\d{4}-\d{4}/).test(phone));
      }
    }
  }, [phase, personType, cpfField, email, phone, socialReason, cnpjField, name])

  useEffect(() => {
    if (phase === 2) {
      setTextButton('FINALIZAR PEDIDO')

      if (paymentType === 1) {
        setEnabledNext(cardNumber.length === 19 && cardName && validity.length === 5 && cvv && installment.description);
      } else if (paymentType === 2) {
        setEnabledNext(cardNumber.length === 19 && cardName && validity.length === 5 && cvv);
      } else {
        setEnabledNext(true);
      }
    }
  }, [phase, paymentType, cardNumber, cardName, validity, cvv, installment]);

  const handleDeliveryOption = (info) => {
    setSelectedIdFreightOption(info.id);
    setFreight(info.price == undefined ? 0 : info.price);
  }

  const isAvailable = (item, id) => {
    if (cep.length !== 9) {
      return false;
    }
    return item.isActive;
  }

  useEffect(() => {
    if (cpfField.length === 14 && !cpf.isValid(cpfField.replace('.', '').replace('.', '').replace('-', ''))) {
      setErrors({ cpf: { message: 'CPF inválido' } });
    } else if (cnpjField.length === 18 && !cnpj.isValid(cnpjField.replace('.', '').replace('.', '').replace('/', '').replace('-', ''))) {
      setErrors({
        cnpj: { message: 'CNPJ inválido' }
      });
    } else {
      setErrors({});
    }
  }, [cpfField, cnpjField]);

  function handleBackButtonClick() {
    history.goBack()
  }

  return (
    <StyledPage onSubmit={(e) => handleSubmit(e)}>
      <StyledLeftContainer>
        <Text fontSize={theme.fontSize._14px} textAlign="left" lineHeight="10px" color="black" className="link" cursor="pointer" onClick={handleBackButtonClick}>
          <Icon icon={returIcon} width="20px" height="10px" />
          Voltar / Meu carrinho
        </Text>
        <h2>MEU CARRINHO</h2>
        <StyledList>
          {products?.map((product) => (
            <Product isEdit={phase !== 3} key={product.id} info={product} onRemove={handleRemove} />
          ))}
        </StyledList>
        {phase === 0 ? (
          <>
            <h2 className="phase-name">Como você deseja receber seu pedido?</h2>
            <StyledInputContainer>
              <Input label="Insira seu CEP para continuar *" placeholder="00000-000" buttonName="BUSCAR" onClick={() => handleCep()} setValue={(e) => setCep(mask(e.target.value, ['99999-999']))} value={cep} />
            </StyledInputContainer>
            <h2 className="delivery">Entrega</h2>
            <StyledDeliveryContainer>
              {deliveryOptions.map((item) => (
                <DeliveryOption handleChange={handleDeliveryOption} key={item.id} selected={selectedIdFreightOption === item.id} available={isAvailable(item, item.id)} internalInfos={item} />
              ))}
            </StyledDeliveryContainer>

            {selectedIdFreightOption !== 1 && (
              <StyledFields>
                <StyledRow className='wrap'>
                  <StyledLabel className="last-field" htmlFor="street">
                    <p>Logradouro *</p>
                    <StyledInput required id="street" value={street} onChange={(e) => setStreet(e.target.value)} />
                  </StyledLabel>
                  <StyledFlexContainer className='no-wrap' direction="row">
                    <StyledLabel htmlFor="number">
                      <p>Número *</p>
                      <StyledInput required id="number" value={number} onChange={(e) => setNumber(e.target.value)} />
                    </StyledLabel>
                    <StyledLabel className="last-field" htmlFor="complement">
                      <p>Complemento</p>
                      <StyledInput id="complement" value={complement} onChange={(e) => setComplement(e.target.value)} />
                    </StyledLabel>
                  </StyledFlexContainer>
                </StyledRow>
                <StyledRow className='wrap'>
                  <StyledLabel className="last-field" htmlFor="city">
                    <p>Cidade *</p>
                    <StyledInput required id="city" value={city} onChange={(e) => setCity(e.target.value)} />
                  </StyledLabel>
                  <StyledFlexContainer className='no-wrap' direction="row">
                    <StyledLabel htmlFor="state">
                      <p>Estado *</p>
                      <StyledInput required id="state" value={state} onChange={(e) => setState(mask(e.target.value, ['AA']))} />
                    </StyledLabel>
                    <StyledLabel className="last-field" htmlFor="district">
                      <p>Bairro *</p>
                      <StyledInput required id="district" value={district} onChange={(e) => setDistrict(e.target.value)} />
                    </StyledLabel>
                  </StyledFlexContainer>
                </StyledRow>
              </StyledFields>
            )}

            {selectedIdFreightOption === 1 && (
              <Modal showCloseButton minWidth="330px" width="80%" maxWidth="500px" height="auto" padding='20px' isVisible overflow="none"
                onCloseCallback={() => {
                  setSelectedIdFreightOption(0);
                  setFreight(deliveryOptions[0].price ?? 0)
                }}>
                Retire na Loja
                <StyledDescr>Selecione a loja que deseja retirar o seu produto.</StyledDescr>
                <StyledListStores>
                  {
                    nearbyStores.length > 0 ?
                      (
                        nearbyStores.map((store) =>
                        (
                          <StoreOption color={settings.buttonColor} selected={store?.storeName === selectedStore?.storeName} key={store.storeName} info={store} onClick={setSelectedStore} />
                        ))
                      ) :
                      (
                        <StyledLoaderContainer align="center" justify="center">
                          <Loader type="Oval" color={theme.colors.lightGreen} height={30} width={30} timeout={loading} />
                        </StyledLoaderContainer>
                      )
                  }
                </StyledListStores>
                <StyledLoaderContainer justify="flex-end">
                  <StyledButtonStore background={settings.buttonColor} disabled={!selectedStore} onClick={() => handleNext()}>
                    CONTINUAR
                  </StyledButtonStore>
                </StyledLoaderContainer>
              </Modal>
            )}
          </>
        ) : (
          <>
            <h2>Entrega</h2>
            {selectedIdFreightOption === 1 ? (
              <ResumeCard
                isEditable={phase !== 3}
                title={selectedStore.storeName}
                firstRow={`${selectedStore.streetName}, ${selectedStore.streetNumber}`}
                secondRow={`${selectedStore.cityName} - ${selectedStore.stateName}`}
                onClick={() => setPhase(0)}
                color={settings.buttonColor}
              />
            ) : (
              <ResumeCard
                isEditable={phase !== 3}
                title="ENDEREÇO DE ENTREGA"
                firstRow={`${street} ${number}, ${complement}`}
                secondRow={`${district} - ${state} | ${cep}`}
                onClick={() => setPhase(0)}
                color={settings.buttonColor}
              />
            )}
          </>
        )}

        {phase === 1 ? (
          <>
            <h2 className="phase-name">Identificação</h2>
            <StyledFields>
              <StyledRow>
                <StyledExternalCircle onClick={() => setPersonType(0)}>
                  {personType === 0 && <StyledInternalCircle color={settings.buttonColor} />}
                </StyledExternalCircle>
                <StyledType>Pessoa Física</StyledType>
                <StyledExternalCircle onClick={() => setPersonType(1)}>
                  {personType === 1 && <StyledInternalCircle color={settings.buttonColor} />}
                </StyledExternalCircle>
                <StyledType>Pessoa Jurídica</StyledType>
              </StyledRow>
              {personType === 0 ? (
                <StyledRow>
                  <StyledLabel htmlFor="name">
                    <p>Nome completo *</p>
                    <StyledInput id="name" onChange={(e) => setName(e.target.value)} value={name} />
                  </StyledLabel>
                  <StyledLabel htmlFor="cpf">
                    <p>CPF *</p>
                    <StyledInput value={cpfField} onChange={(e) => setCpfField(mask(e.target.value, ['999.999.999-99']))} id="phone" />
                    {errors.cpf && (
                      <FieldError>{errors.cpf.message}</FieldError>
                    )}
                  </StyledLabel>
                </StyledRow>
              ) : (
                <StyledRow>
                  <StyledLabel htmlFor="socialReason">
                    <p>Razão Social *</p>
                    <StyledInput id="socialReason" onChange={(e) => setSocialReason(e.target.value)} value={socialReason} />
                  </StyledLabel>
                  <StyledLabel htmlFor="cnpj">
                    <p>CNPJ *</p>
                    <StyledInput value={cnpjField} onChange={(e) => setCnpjField(mask(e.target.value, ['99.999.999/9999-99']))} id="cnpj" />
                    {errors.cnpj && (
                      <FieldError>{errors.cnpj.message}</FieldError>
                    )}
                  </StyledLabel>
                </StyledRow>
              )}
              <StyledRow>
                <StyledLabel htmlFor="email">
                  <p>Email *</p>
                  <StyledInput type="email" id="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                </StyledLabel>
                <StyledLabel htmlFor="phone">
                  <p>Telefone *</p>
                  <StyledInput value={phone} onChange={(e) => setPhone(mask(e.target.value, ['(99) 9 9999-9999']))} id="phone" />
                </StyledLabel>
              </StyledRow>
            </StyledFields>
          </>
        ) : phase > 1 && (
          <>
            <h2>Identificação</h2>
            <ResumeCard
              isEditable={phase !== 3}
              title="ENTREGAR PARA"
              firstRow={`${personType === 0 ? name : socialReason} - ${personType === 0 ? cpfField : cnpjField}`}
              secondRow={`${email} | ${phone}`}
              onClick={() => setPhase(1)}
              color={settings.buttonColor}
            />
          </>
        )}

        {phase === 2 ? (
          <>
            <h2 className="phase-name">Pagamento</h2>
            <StyledRow>
              <PaymentCard selected={paymentType === 1} buttonColor={settings.buttonColor} icon={Bandeiras} title="Cartão de Crédito" onClick={() => setPaymentType(1)} />
              {/* <PaymentCard selected={paymentType === 2} icon={Bandeiras} title="Cartão de Débito" onClick={() => setPaymentType(2)} /> */}
              <PaymentCard className="pix" buttonColor={settings.buttonColor} selected={paymentType === 4} icon={PixIcon} title="Pix - QRCode" onClick={() => setPaymentType(4)} />
            </StyledRow>
            {paymentType !== 4 && (
              <StyledCardContainer>
                <StyledFormCardContainer>
                  <StyledRow>
                    <StyledLabel htmlFor="card">
                      <p>Número do Cartão</p>
                      <StyledInput id="card" placeholder='0000 0000 0000 0000' onChange={(e) => setCardNumber(mask(e.target.value, ['9999 9999 9999 9999']))} value={cardNumber} />
                    </StyledLabel>
                  </StyledRow>
                  <StyledRow>
                    <StyledLabel htmlFor="cardName">
                      <p>Nome impresso no cartão</p>
                      <StyledInput id="cardName" onChange={(e) => setCardName(e.target.value)} value={cardName} />
                    </StyledLabel>
                  </StyledRow>
                  <StyledRow>
                    <StyledContainer>
                      <StyledLabel htmlFor="validity">
                        <p>Validade</p>
                        <StyledInput id="validity" placeholder="00/00" onChange={(e) => setValidity(mask(e.target.value, ['99/99']))} value={validity} />
                      </StyledLabel>
                      <StyledLabel htmlFor="cvv">
                        <p>CVV</p>
                        <StyledInput value={cvv} placeholder="000" onChange={(e) => setCvv(mask(e.target.value, ['9999']))} id="cvv" />
                      </StyledLabel>
                    </StyledContainer>
                  </StyledRow>
                  {paymentType === 1 && (
                    <StyledRow>
                      <StyledLabel>
                        <p>Quantidade parcelas</p>
                        <Select
                          name="customer-seller"
                          inputId="customerSeller"
                          options={installmentOptions}
                          value={installment}
                          menuPlacement="top"
                          getOptionLabel={(option) => option.description}
                          getOptionValue={(option) => option.value}
                          placeholder="Selecione"
                          onChange={(value) => setInstallment(value)}
                        />
                      </StyledLabel>
                    </StyledRow>
                  )}
                </StyledFormCardContainer>
                {windowDimensions.width >= 575 && (
                  <StyledFlexContainer direction="column" align="center" justify="flex-end">
                    <StyledCardImageContainer src={CardImage} alt="Imagem de cartão" />
                  </StyledFlexContainer>
                )}
              </StyledCardContainer>
            )}
            {paymentType === 4 && (
              <StyledPixContent>
                <StyledRowContent>
                  <img className='pix-icon' src={PixIcon} alt="ícone do pix" />
                  <div>
                    <h3>Pagar com Pix</h3>
                    <p>pague com Pix em qualquer dia e a qualquer hora! o pagamento é instantâneo, prático e pode ser feito em poucos segundos. é muito rápido e seguro :)</p>
                  </div>
                </StyledRowContent>
              </StyledPixContent>
            )}
          </>
        ) : phase > 2 && (
          <>
            <h2>Pagamento</h2>
            <ResumeCard isEditable={phase !== 3} title="PAGAMENTO" firstRow={getFirstRow()} secondRow={getSecondRow()} onClick={() => setPhase(2)} color={settings.buttonColor} />
          </>
        )}

        {debitModal && (
          <Modal showCloseButton width="360px" height="250px" padding={0} onCloseCallback={() => setDebitModal(false)} isVisible>
            <ModalContainer>
              <SuccessContainer>
                <img src={SuccessIcon} alt="Icone de sucesso" />
              </SuccessContainer>
              <p>Está quase lá! Para finalizar, preencha o campo da nova guia com o código exibido lá.</p>
              <StyledButton color={theme.colors.white} bgcolor={theme.colors.black} height="30px" magleft="0" width="90px" onClick={() => finishOperation()}>
                {isLoadingStatus ? (
                  <Loader type="Oval" color={theme.colors.white} height={20} width={20} timeout={0} />
                ) : 'Preenchi'}
              </StyledButton>
              <ProcessingContainer>
                {isProcessing && "Seu pagamento está em processamento."}
              </ProcessingContainer>
            </ModalContainer>
          </Modal>
        )}

        {pixModal && (
          <Modal width="90vw" maxWidth="750px" height='100%' maxHeight={windowDimensions.width >= 570 ? '60vh' : '90vh'} padding={0} isVisible showCloseButton={false} >
            <ModalContainerPix >
              <StyledRowContent>
                <img className='pix-icon' src={PixIcon} alt="ícone do pix" />
                <div className='header'>
                  <h3>Pix</h3>
                  <p>Estamos quase lá! Faça o pagamento com Pix usando o aplicativo para pagamentos de sua preferência.</p>
                </div>
              </StyledRowContent>
              <StyledRowContent className='pix-container'>
                <div className="pix-code">
                  <img src={qrCode} alt="imagem do QRCode" />
                  <p>Código pix</p>
                  <StyledLinkContainer>
                    <p>{pixCode}</p>
                    <StyledCopyButton background={!copied ? theme.colors.lightGreen : theme.colors.green} onClick={handleCopy}>
                      {!copied ? 'COPIAR' : 'COPIADO!'}
                    </StyledCopyButton>
                  </StyledLinkContainer>
                </div>
                <ol>
                  <li>
                    <StyledOrderContainer>
                      1
                    </StyledOrderContainer>
                    Abra o app do seu banco ou instituição financeira e entre no ambiente Pix.
                  </li>
                  <li>
                    <StyledOrderContainer>
                      2
                    </StyledOrderContainer>
                    Escolha a opção pagar com qr code e escaneie ou copie código exibido nesta tela.
                  </li>
                  <li>
                    <StyledOrderContainer>
                      3
                    </StyledOrderContainer>
                    Confirme as informações e finalize a compra.
                  </li>
                  <li>
                    <StyledOrderContainer>
                      4
                    </StyledOrderContainer>
                    Após realizar o pagamento com Pix, retorne a esta tela e clique no botão 'Concluir Compra'.
                  </li>
                </ol>
              </StyledRowContent>
              <StyledAlert background={isLoadingStatus ? theme.colors.ice : '#FFE5E5'}>
                {
                  isLoadingStatus ? (
                    <Loader type="Oval" color={theme.colors.lightGreen} height={20} width={20} timeout={loading} />
                  ) : (`O seu pagamento ainda não foi confirmado. Verificando novamente em ${timer}`)
                }
              </StyledAlert>
              <StyledButtonsContainer background={settings.buttonColor}>
                <button type='button' className='abort-button' onClick={() => handleAbort()}>
                  Abandonar pagamento
                </button>
                <button type='button' background={settings.buttonColor} onClick={() => finishOperation()} className='continue-button'>
                  Concluir compra
                </button>
              </StyledButtonsContainer>
            </ModalContainerPix>
          </Modal>
        )}

        {isSuccess !== null && (
          <Modal showCloseButton onCloseCallback={handleLinkToResultClose} style={{ marginLeft: 0, marginRight: 0 }} width="90vw" maxWidth="750px" height="100%" maxHeight={windowDimensions.width >= 570 ? '235px' : '350px'} padding={0} isVisible >
            <ModalContainer>
              <Operation type={isSuccess ? "success" : "error"}
                handleMsg={responseApiErro}
                buttonColor={settings.buttonColor}
                reference={reference}
                handleShowSuccess={handleLinkToCloseSuccess}
                handleShowError={handleLinkToCloseError} />
            </ModalContainer>
          </Modal>)
        }

      </StyledLeftContainer>
      <StyledRightContainer>
        <OrderSummary
          subTotal={total}
          total={total - discounts + freight}
          discount={discounts}
          freight={freight}
          disabled={!enabledNext}
          handleNext={handleNext}
          isLoading={loading}
          textButton={textButton}
          disabledDiscount={phase === 3}
          handleShowCase={handleLinkToShowcase}
          store={match.params.store}
          sellerId={sellerId}
          promocode={promocode}
          setPromocode={setPromocode}
          phase={phase}
          buttonColor={settings.buttonColor}
        />
      </StyledRightContainer>
    </StyledPage>
  );
}

export default NewCheckout;
